/* .container {
  padding: 3.5rem 5rem 3.5rem 5rem;
  background-color: #f4f4f4;
} */

.container h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.profile-head {
  width: 32%;
}
.profile-head h1 {
  font-size: 26px;
  margin: 0;
  color: #000020;
}

.profile-head p {
  font-size: 12px;
  margin: 0;
  color: #252525;
}

.profile-btns {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 22rem;
}

.profile-sec {
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  padding: 1.5rem;
  justify-content: space-between;
}

.profile-sec-1 {
  display: flex;
  height: 13vh;
}

.profile-sec-2-head{
    display: flex;
    justify-content: space-between;
}

.profile-user {
  /* border: 1px solid red; */
  white-space: nowrap;
  align-items: c;
}

/* .pro-pic {
  margin-right: 1rem;
  border: 1px solid #afafaf;
  border-radius: 50%;
  max-width: 5rem;
  height: 100%;
  overflow: hidden;
} */

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user h5 {
  margin: 0;
  font-size: 1rem;
}
.user h6 {
  margin: 0;
  font-weight: normal;
  font-weight: 500;
  font-size: 12px;
}

.profile-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-sec-2-contents {
  /* border: 1px solid red; */
  justify-content: space-between;
  width: 30%;
}
.sec-2-content-element{
    margin: 1.5rem 0 1.5rem 0;
}

.sec-2-content-element h5:nth-child(1) {
  font-size: 13px;
  font-weight: 500;
  color: #8a92a6;
}

.sec-2-content-element h5:nth-child(2) {
  font-weight: 600;
}

.profile-sec-3-contents{
    justify-content: space-between;
    width: 25.5%;
}
