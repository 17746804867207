.pdf-wrapper {
    position: absolute; 
    top: 1rem;
    left: 1rem;
    width: 98vw;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  
  .pdf {
    width: 100%;
    height: 100%;
    overflow: scroll;
    align-self: center;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
  }
  
  
  .pdf-page-info {
    font-size: 14px;
  }
  
  .pdf-navigation button {
    margin-left: 8px;
  }
  
  .closeButton-pdf {
    position: fixed;
    right: 2%;
    width: 30px;
    height: 30px;
    border-radius: 10%;
    top: 3%;
    border: 2px solid #ccc;
    opacity: 0.7;
    font-weight: 600;
    /* border: none; */
    transition: all 0.1s ease-in-out;
    z-index: 99;
  }
  
  .closeButton-pdf:hover {
    opacity: 1;
    /* scale: 1.2; */
    cursor: pointer;
    color: red;
  }
  
  /* .closePdf {
    background-color: transparent;
    transform: translateX(5%);
    border: none;
    font-size: 16px;
    cursor: pointer;
  } */
  
  /* .closePdf:hover {
    color: #f00;
  } */
  
  .react-pdf__message,
  .loading_pdf {
    color: #fff;
  }
  
  .page-controls {
    position: fixed;
    bottom: 1%;
    left: 50%;
    background: white;
    opacity: 1;
    transform: translate(-50%);
    transition: opacity ease-in-out .2s;
    box-shadow: 1px -20px 40px 0 rgba(16, 36, 94, .2);
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 2;
    display: flex;
    align-items: center;
  }
  
  /* .reader:hover .page-controls {
      opacity: 1!important;
  } */
  .page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    line-height: 24px;
    font-size: 24px;
    border-radius: 4px;
  }
  
  .page-controls span {
    font: inherit;
    font-size: 14px;
    padding: 0 0.5em;
  }
  
  .page-controls button:enabled:hover,
  .page-controls button:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  
  .image-viewer-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  
  }
  
  .image-viewer {
    position: relative;
    width: 80%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .image-viewer-image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  }
  
  .image-viewer-close-button {
    position: absolute;
    right: 13px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    top: 13px;
    opacity: 0.5;
    font-weight: 600;
    border: none;
    transition: all 0.1s ease-in-out;
  }
  
  .image-viewer-close-button:hover {
    opacity: 1;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
  
  
  
  .videoPlayer {
    /* width: 100vw; */
    /* box-shadow: 1rem 1rem 1rem 1rem pink; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    background-color: black;
    border-radius: 0.5rem;
    position: relative;
  }
  
  .videoWrapper {
    display: contents;
  }
  
  .player {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }
  
  .closeButton {
    position: absolute;
    right: 13px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    top: 13px;
    opacity: 0.7;
    font-weight: 600;
    border: none;
    transition: all 0.1s ease-in-out;
  }
  
  .closeButton:hover {
    opacity: 1;
    scale: 1.2;
    cursor: pointer;
    color: red;
  }
  
  /* .mp3-player-container {
    position: relative;
    max-width: 300px;
    border-radius: 1rem;
    /* border-radius: 0.5rem; 
  } */
  
  .close-button {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: black;
    opacity: 20%;
    border-radius: 25%;
    border: none;
    color: #ccc;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.3s ease-in-out
  }
  
  .close-button:hover {
    opacity: 100%;
  }
  
  /* Customize the appearance of the audio player elements as needed */
  /* For example, you can target the progress bar, play button, etc. */
  
  
  /* Add more styles based on your design preferences */