* {
  font-family: "Poppins";
  /* overflow: hidden; */
}



@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

.dflex {
  display: flex;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #2c2b83;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2c2b83;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
