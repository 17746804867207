@media only screen and (max-width : 576px){
    .careerContainer{
        padding: 5%;
    }

    .doctor-data{
        flex-direction: column;
        align-items: center;
        height: 35vh;
        text-align: center;
    }

    .doctor-text{
        width : 100%;
    }

    .doctor-pic{
        width: 120px;
        height: 120px;
    }

}
