.container{
    width: 100%;
    height : auto;
    background-color: #ffffff;
    padding: 3% 8% 3% 8%;
}

/* .editProfile{
    /* position: relative; 
    height: auto;
    /* border-radius: 2rem;
    /* box-shadow: rgba(50, 50, 167, 0.4)0px 5px 15px;
} */

.edit-doctor{
    transform : translateY(1.5rem);
    z-index: 9;
}

/* .editForm{
    transform: translateY(-2.8%);
} */

.editprofile-form{
    /* position: relative; */
    /* top: 22vh; */
    width: 100%;
    padding: 5%;
    box-shadow: rgba(50, 50, 167, 0.4)0px 5px 15px;
    border-radius: 2rem;
}

.head-text{
    width: 100%;
}


.form-sec-1{
    /* border : 1px solid red; */
    width: 83%;
    justify-content: space-between;
    /* border-bottom: 1px solid #D9D9D9; */
    margin-bottom: 5%;
}

.check-container{
    transform: translateX(-0.7rem);
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;

}