.max100 {
  max-width: 100%;
  max-height: 100%;
}

.rounded {
  border-radius: 0.5rem;
  overflow: hidden;
}

.dflex {
  display: flex;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.mr1 {
  margin-right: 1rem;
}

.mr05 {
  margin-right: 0.5rem;
}

.mt05 {
  margin-top: 0.4rem;
}

.mt01 {
  margin-top: 0.1rem;
}

.mr01 {
  margin-right: 0.1rem;
}

.container {
  padding: 3.5rem 5rem 3.5rem 5rem;
  background-color: #f4f4fA;
}

.sec-1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  /* border: 1px solid red; */
  /* height: 34vh */
}

.profile {
  /* border: 1px solid blue; */
  width: 50%;
  overflow: hidden;
  border-radius: 0.5rem;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  flex-wrap: wrap;
  background-color: #fff;
}

.propic {
  /* border : 1px solid green; */
  width: 10.5rem;
  height: auto;
  border-radius: 0.5rem;
  overflow: hidden;
}

.details {
  /* border: 1px solid yellow; */
  width: auto;
  border-radius: 0.5rem;
}

.name {
  font-size: 1.5rem;
  letter-spacing: "0em";
  text-align: "left";
  color: "#4A4A4A";
}

.hospital {
  display: flex;
}

.main-box {
  padding: 1.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5rem;
  background-color: #fff;
}

.usage {
  position: relative;
  width: 30%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  border: 1px solid red;
}

.usage h5 {
  position: absolute;
  font-size: 1rem;
  top: 0rem;
}

.usage .graph {
  position: absolute;
  top: 5rem;
  display: flex;
}

.usage h6 {
  position: absolute;
  bottom: 0;
}

.usage .legends {
  position: relative;
  margin-left: 1rem;
  margin-top: 3rem;
}

.usage .legends h5 {
  position: static !important;
  margin: 0 0 0 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.6rem;
  font-weight: 600;
}

.usage .legend {
  width: 1.3rem;
  height: 1.3rem;
  margin-top: 0.5rem;
}

.usage h6 {
  font-weight: 500;
}

.files {
  margin-left: 1rem;
  padding-top: 2.5rem;
  width: 17%;
}

.files h5 {
  margin: 0;
  font-size: 1rem;
}

.file-ico {
  width: 5rem;
}

.file-count h6 {
  font-weight: normal;
  font-size: 0.8rem;
  margin: 0;
  transform: translateY(1rem);
  white-space: nowrap;
}

.page-ico {
  width: 2rem;
}

.files .link {
  margin-top: 0.8rem;
}

.files .link a {
  color: #eb568f;
  white-space: nowrap;
  font-size: 0.8rem;
}

.files .link a:hover {
  opacity: 80%;
}

.sec-2 {
  /* justify-content: space-between; */
  margin-top: 2rem;
}

.sec-2 h4 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 500;
  color: #2c2b83;
}
.events {
  width: 50%;
}

.news {
  margin-left: 1rem;
  width: 48%;
}

.event-btns {
  width: 42%;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.event-content {
  /* border: 1px solid red; */
  margin-top: 1.5rem;
}

.event-content .e-content {
  justify-content: space-between;
}

.e-content h6 {
  margin: 0;
  font-weight: normal;
  color: #525252;
}

.event-content .e-img {
  width: 28%;
}

.event-content .e-text {
  width: 68%;
}

.event-content .e-content {
  border: 1px solid #d7d7d7;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 8rem;
}

.e-content .e-text {
  margin-top: 1rem;
  margin-right: 1rem;
}

.e-text-small {
  /* border: 1px solid red; */
  width: 100% !important;
  justify-content: space-between;
}

.e-text-title {
  width: 50%;
  margin-top: 0.5rem;
}
.e-text-title h4 {
  color: #252525;
}

.e-text-book {
  margin-top: 0.5rem;
}

.e-text-book h5 {
  margin: 0;
  font-weight: normal;
}

.news-content {
  /* border : 1px solid red; */
  margin-top: 0.6rem;
  border-bottom: 1px solid #d9d9d9;
}

.ncontent {
  width: 100%;
}
.n-content .n-img {
  width: 20%;
  margin-right: 1rem;
}

.n-title {
  /* border: 1px solid red; */
  width: 68%;
}

.n-title h6 {
  font-weight: normal;
}

.n-title h4 {
  color: #252525;
}

.n-text-small {
  justify-content: space-between;
}

.n-text-small img {
  margin-top: 1.5rem;
}

.n-text-small img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
  .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pro-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .usage {
    width: 45%;
    height: 15rem;
  }

  .usage .graph {
    top: 4rem;
  }

  .usage .legends {
    margin-left: 4rem;
    margin-top: 2rem;
  }

  .files {
    position: absolute;
    width: 45%;
    margin: 0;
    right: 2.5rem;
    bottom: 0;
    height: 45%;
    display: flex;
    flex-direction: row;
  }

  .files h5 {
    transform: translate(-5rem, -1rem);
  }

  .file-ico {
    margin-top: 2.5rem;
  }

  .file-count {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1100px) {
  .profile {
    width: 70%;
  }

  .contacts {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hospital {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .hospital span {
    white-space: nowrap;
  }

  .mobile {
    transform: translateX(-0.8rem);
  }

  .join {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 2rem 0 2rem; */
    /* white-space: nowrap; */
  }

  .usage {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    margin: 1rem 0 0 0;
  }

  .usage h5 {
    position: static;
    margin-right: 2rem;
  }

  .usage .graph {
    position: static;
  }

  .usage h6 {
    position: absolute;
  }

  .files {
    position: static;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .files h5 {
    transform: translate(-10rem, 0);
  }

  .file-ico {
    margin-top: 0;
    transform: translateX(5rem);
  }

  .file-count {
    margin-top: 0;
    transform: translate(3.4rem, -0.5rem);
  }

  .files .link {
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 1100px) {
  .profile {
    width: 100%;
  }
}

@media only screen and (max-width: 920px) {
  .usage {
    flex-direction: column;
    height: 18rem;
  }

  .usage h5:nth-child(1) {
    margin: 0;
    transform: translateY(-1.5rem);
  }

  .files {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12rem;
  }

  .files h5 {
    transform: translate(0, -4rem);
    white-space: nowrap;
  }

  .file-ico {
    margin-top: 0;
    transform: translateX(0);
  }

  .file-count {
    margin-top: 0;
    transform: translate(0, 0);
  }

  .files .link {
    margin-top: 0rem;
  }
}
